import {
  type BannerAwardFragment,
  BorderRadius,
  CParagraphMarginBottom,
  CTextWeight,
  Theme,
} from '@seek/cmsu-cms-connect';
import { Box, ContentBlock, Stack, Column, Columns } from 'braid-design-system';
import { ActionGroup } from '../../../ActionGroup/ActionGroup';
import { Heading } from '../../../Heading/Heading';
import { Paragraph } from '../../../Paragraph/Paragraph';

import SaraContentBackgroundImage from '../images/sara-banner-text-background.png';
import SaraBannerBackgroundImage from '../images/sara-banner-default.png';

import { vars } from 'braid-design-system/css';
import { Video } from '../../../Video/Video';
import {
  getBackgroundColor,
  getContentBackgroundColor,
  getContentBackgroundType,
  getThemeBorder,
  getThemeTone,
  getVideoUrl,
} from '../helpers';

type Props = Omit<BannerAwardFragment, '__typename' | 'id'>;

export const SaraBanner = ({
  paragraph,
  heading,
  actionGroup,
  backgroundImage,
  video,
}: Props) => {
  const minHeight = '500px';
  const videoUrl = getVideoUrl(video);
  const theme = Theme.Sara;

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        padding={{ mobile: 'small', tablet: 'gutter' }}
        position="relative"
        data={{ testid: 'AwardsBannerTestId' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        background={!backgroundImage ? getBackgroundColor(theme) : undefined}
        style={{
          minHeight,
          maxWidth: '2000px',
          backgroundImage: `url(${
            backgroundImage ? backgroundImage.url : SaraBannerBackgroundImage
          })`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        width="full"
      >
        <Box
          boxShadow={
            backgroundImage ? getThemeBorder(theme) : 'borderBrandAccent'
          }
          width="full"
          height="full"
          paddingX={
            getThemeBorder(theme) && { mobile: 'small', tablet: 'gutter' }
          }
          paddingY={{ mobile: 'small', tablet: 'gutter' }}
          style={{
            minHeight: `calc(${minHeight} - ${vars.space.gutter})`,
          }}
          display="flex"
          flexDirection="column"
          justifyContent={{ mobile: 'flexStart', tablet: 'center' }}
          alignItems="center"
        >
          <ContentBlock width="large">
            <Columns
              space="xlarge"
              collapseBelow="desktop"
              alignY={{ mobile: 'top', tablet: 'center' }}
            >
              <Column width={'2/5'}>
                <Box
                  id="content"
                  borderRadius="large"
                  background={getContentBackgroundType(theme)}
                  padding={
                    !video && backgroundImage
                      ? 'large'
                      : { mobile: 'gutter', desktop: 'none' }
                  }
                  style={{
                    backgroundImage: backgroundImage
                      ? `url(${SaraContentBackgroundImage})`
                      : undefined,

                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: backgroundImage
                      ? getContentBackgroundColor(theme)
                      : undefined,
                  }}
                >
                  <Stack space="medium">
                    {heading?.text ? (
                      <Heading
                        {...heading}
                        weight={heading.weight || CTextWeight.Regular}
                      />
                    ) : null}
                    {paragraph && (
                      <Box paddingY="small">
                        <Paragraph
                          content={paragraph.Paragraph_text?.raw}
                          marginBottom={CParagraphMarginBottom.None}
                          align={paragraph.align}
                          tone={paragraph.tone || getThemeTone(theme)}
                          size={paragraph.size}
                        />
                      </Box>
                    )}
                    {actionGroup && <ActionGroup {...actionGroup} />}
                  </Stack>
                </Box>
              </Column>
              <Column width="3/5">
                {videoUrl && (
                  <Box data={{ testid: 'video' }}>
                    <Video
                      size="small"
                      borderRadius={BorderRadius.Xlarge}
                      url={videoUrl}
                      alignX="right"
                    />
                  </Box>
                )}
              </Column>
            </Columns>
          </ContentBlock>
        </Box>
      </Box>
    </Box>
  );
};
