import {
  type BannerAwardFragment,
  BorderRadius,
  CTextWeight,
  Theme,
} from '@seek/cmsu-cms-connect';
import { Box, ContentBlock, Stack, Column, Columns } from 'braid-design-system';
import { ActionGroup } from '../../ActionGroup/ActionGroup';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';

import { vars } from 'braid-design-system/css';
import { Video } from '../../Video/Video';
import { useCMSUContext } from '../../../hooks/useCMSUContext';
import { StarBanner } from './components/StarBanner';
import { getVideoUrl } from './helpers';
import { SaraBanner } from './components/SaraBanner';

type Props = Omit<BannerAwardFragment, '__typename' | 'id'>;

export const AwardsBanner = ({
  paragraph,
  heading,
  actionGroup,
  backgroundImage,
  video,
}: Props) => {
  const { theme } = useCMSUContext();
  const minHeight = theme === Theme.Star ? '380px' : '500px';
  const videoUrl = getVideoUrl(video);
  if (theme === Theme.Star) {
    return (
      <StarBanner
        paragraph={paragraph}
        heading={heading}
        actionGroup={actionGroup}
        backgroundImage={backgroundImage}
        video={video}
      />
    );
  }

  if (theme === Theme.Sara) {
    return (
      <SaraBanner
        paragraph={paragraph}
        heading={heading}
        actionGroup={actionGroup}
        backgroundImage={backgroundImage}
        video={video}
      />
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        padding={{ mobile: 'small', tablet: 'gutter' }}
        position="relative"
        data={{ testid: 'AwardsBannerTestId' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight,
          maxWidth: '2000px',
          ...(backgroundImage && {
            backgroundImage: `url(${backgroundImage.url})`,
          }),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        width="full"
      >
        <Box
          width="full"
          height="full"
          paddingX={{ mobile: 'small', tablet: 'gutter' }}
          paddingY={{ mobile: 'small', tablet: 'gutter' }}
          style={{
            minHeight: `calc(${minHeight} - ${vars.space.gutter})`,
          }}
          display="flex"
          flexDirection="column"
          justifyContent={{ mobile: 'flexStart', tablet: 'center' }}
          alignItems="center"
        >
          <ContentBlock width="large">
            <Columns
              space="xlarge"
              collapseBelow="desktop"
              alignY={{ mobile: 'top', tablet: 'center' }}
            >
              <Column width="2/5">
                <Box
                  id="content"
                  borderRadius="large"
                  background="surface"
                  padding={!video ? 'large' : undefined}
                >
                  <Stack space="medium">
                    {heading?.text ? (
                      <Heading
                        {...heading}
                        weight={heading.weight || CTextWeight.Regular}
                      />
                    ) : null}
                    {paragraph && (
                      <Box paddingY="small">
                        <Paragraph
                          content={paragraph.Paragraph_text?.raw}
                          {...paragraph}
                        />
                      </Box>
                    )}
                    {actionGroup && <ActionGroup {...actionGroup} />}
                  </Stack>
                </Box>
              </Column>
              <Column width="3/5">
                {videoUrl && (
                  <Box data={{ testid: 'video' }}>
                    <Video
                      size="small"
                      borderRadius={BorderRadius.Xlarge}
                      url={videoUrl}
                      alignX="right"
                    />
                  </Box>
                )}
              </Column>
            </Columns>
          </ContentBlock>
        </Box>
      </Box>
    </Box>
  );
};
